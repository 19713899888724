import "./Spinner.css";

const Spinner = () => {
  //   return <div className="lds-dual-ring"></div>;
  return (
    <div className="pageLoader">
      <div className="lds-dual-ring"></div>
    </div>
  );
};

export default Spinner;
