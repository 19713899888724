// Core Imports
import { combineReducers } from "redux";

// Custom Imports
import authReducer from "./auth-reducer";

const rootReducer = combineReducers({
  authR: authReducer,
});

export default rootReducer;
