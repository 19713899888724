// Core Imports
import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";

// Custom Imports
import rootReducer from "./reducers";

const composerEnhancer  =window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appStore = createStore(
  rootReducer,
  composerEnhancer(applyMiddleware(thunk))
);

export default appStore;
