const Utils={
    updateObject: async function (oldObj, updatedObj) {
        console.log("your old object", oldObj);
        console.log("your old object", updatedObj);
        return {...oldObj, ...updatedObj};
    },
    redirectWithToken: function (url, access_token, userRole) {
        let target_url = url + '/authorize?access_token=' + access_token + '&role=' + userRole;
        console.log("Redirecting with Token", url,access_token,userRole,target_url)
        window.location.href = target_url
    }
};
export default Utils