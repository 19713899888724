// Core Imports
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import Spinner from './components/pageLoader/Spinner'
import HttpsRedirect from 'react-https-redirect';
// Custom Imports

import "./index.css";
import appStore from "./store";
const App = lazy(() => import("./App"))

ReactDOM.render(
  <Provider store={appStore}>
    <BrowserRouter>
      <Suspense fallback={<Spinner/>}>
        <HttpsRedirect>
          <App />
        </HttpsRedirect>
      </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
